import { Outlet, Link } from 'react-router-dom';

import logo from '@/assets/unq-logo-nav.png';
import { publicNavLinks } from './Nav';
import { Toaster } from '../ui/toaster';

const PublicCleanLayout = () => {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="w-full bg-slate-50 border-b  shadow-sm">
        <div className="flex justify-between items-center container mx-auto  p-4 ">
          <img
            alt="UnQ SCS"
            className="logo mr-auto"
            src={logo}
            style={{ height: '40px' }}
          />
          <div className="flex justify-between items-center space-x-4 font-bold">
            {publicNavLinks.map((route) => (
              <div key={route.name + route.link} className="flex items-center hover:font-extrabold space-x-2">
                <Link to={route.link}>{route.name}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative flex flex-col grow w-full h-full">
        <Outlet />
        <Toaster />
      </div>
    </div>
  );
};

export default PublicCleanLayout;
